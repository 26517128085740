"use strict";

$(document).ready(function () {
	$(".row").each(function () {
		$(".eq", this).equalHeights();
	});

	if ($(document).height() > $(window).height()) {
		$('#footerwrap').css('position', 'relative');
	}
});

var mastheadScroller = {
	screenMdMin: 992,
	timeout: null,
	throttler: function throttler() {
		if (!mastheadScroller.timeout) {
			mastheadScroller.timeout = setTimeout(function () {
				mastheadScroller.timeout = null;
				mastheadScroller.handler();

				// The handler will execute at a rate of 15fps
			}, 66);
		}
	},
	handler: function handler() {
		if ($(window).width() > mastheadScroller.screenMdMin) {
			$('.navbar-inner, .navbar-brand').toggleClass('scrolled animate', $(window).scrollTop() > 25);
		}
	}
};

$(window).scroll(function () {
	mastheadScroller.throttler();
});